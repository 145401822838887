html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

$max-page-width: 1080px;
$margin-none: 0px !important;
$padding-none: 0px !important;
$hirexl-theme-primary-color: #4981cd;
$hirexl-theme-primary-text-color: #ffffff;

.mr-none {
  margin: $margin-none;
}

.pd-none {
  padding: $padding-none;
}

.font-logo {
  font-family: "Montserrat", sans-serif;
}

.theme-color-white {
  color: $hirexl-theme-primary-text-color !important;
}

.container {
  max-width: $max-page-width !important;
}

/* Register page*/
.register-onboarding-wrapper {
  margin: 0 auto !important;
  min-height: 100vh;
  background: linear-gradient(180deg, #4981cd 0, #4981cd);

  .p-6 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.error-feedback {
  color: #cd201f;
  padding: 0px 25px;
  width: 21em;
}

.error {
  color: #cd201f;
}

.social-ico {
  font-size: 18px !important;
  padding: 0 5px;
}
/* WYSWYG Editor */
.rdw-editor-main {
  border: 1px solid #dddd;
  padding: 5px;
  min-height: 200px;
  .public-DraftStyleDefault-block {
    margin: 0px;
  }
}

.html-viewer {
  ol,
  ul {
    margin-block-start: 1rem !important;
    margin: 1rem;
  }
}
